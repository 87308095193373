export const LOCAL_URL=`http://localhost:7000`
export const DEV_URL=`https://my-car-rent.onrender.com`

export const PROD_URL=""
export const URL=DEV_URL

export const END_POINT={
  CAR:`${URL}/car`,
  USER:`${URL}/user`,
  RENTAL:`${URL}/rental`,
  
}